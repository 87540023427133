import React, { Component } from "react";
import Logo from "../images/fdLogo.svg";
import swal from "sweetalert";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import { Button } from "reactstrap";

import { User, UserCheck, Mail, Phone, Lock, Eye, EyeOff } from "react-feather";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: "",
      passwordConfirmation: "",
      value: "1",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      passErrorMsg: "",
      confirmErrorMsg: "",
      id: "",
      profileImage: "",
      username: "",
      errorCode: "",
      emailExistsError: false,
    };
  }
  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({
          confirmErrorMsg: "The 2 Passwords dont match",
        });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  };

  passwordConfirmation = (e) => {
    // console.log(e.target.value);
    this.setState({ passwordConfirmation: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({
          confirmErrorMsg: "The 2 Passwords dont match",
        });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  };

  register = async () => {
    const vm = this;
    const regEx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
    const regExEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    console.log(vm.state);
    if (
      regEx.test(vm.state.password) &&
      regExEmail.test(vm.state.email) &&
      vm.state.firstName !== "" &&
      vm.state.username !== "" &&
      vm.state.lastName !== "" &&
      vm.state.email !== "" &&
      vm.state.password === vm.state.passwordConfirmation &&
      vm.state.phone !== ""
    ) {
      console.log("True");
      vm.setState({ loading: true });

      let data = {
        username: vm.state.username,
        firstName: vm.state.firstName,
        lastName: vm.state.lastName,
        password: vm.state.password,
        email: vm.state.email,
        passwordConfirmation: vm.state.passwordConfirmation,
        phone: vm.state.phone,
      };

      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/account/register/verify`,
          JSON.stringify(data),
          {
            headers: header,
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.code === "SUCCESS") {
            sessionStorage.setItem("tempUser", res.config.data);
            sessionStorage.setItem(
              "smsVerificationToken",
              res.data.data.smsVerificationToken
            );

            vm.setState({ loading: false });
            window.location.href = "/verifyuser";
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          vm.setState({ loading: false });
          if (error.response.data.code === "VALIDATION_ERROR") {
            swal({
              title: "Error!",
              text: `${error.response.data.message}`,
              icon: "info",
              button: "Ok",
            });
          } else {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "info",
              button: "Ok",
            });
          }
        });
    } else if (!regEx.test(vm.state.password)) {
      console.log("FAIL");
      vm.setState({
        passErrorMsg:
          "Password needs: 1 uppercase letter,1 lowercase letter, 1 special character, 1 digit and minimum 8 characters",
      });
    } else if (!regExEmail.test(vm.state.email)) {
      console.log("FAIL");
      vm.setState({
        passErrorMsg: "Invalid Email",
      });
    } else {
      vm.setState({
        passErrorMsg:
          "You need to fill all the info except for the optional one",
      });
    }
  };

  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value }, () => console.log(target.value));
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  responseGoogle = async (response) => {
    console.log("here", response);
    const vm = this;

    let data = {};
    data.accessToken = response.tokenId;

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API}/account/login/google`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then(function (res) {
        console.log(res);

        if (res.code === "INVALID_CREDENTIALS") {
          // console.log('wrong info');
          vm.setState({ errorCode: res.message });
        } else if (res.data.code === "SUCCESS") {
          sessionStorage.setItem("accessToken", res.data.data.accessToken);
          // localStorage.setItem("refreshToken", res.data.refreshToken);
          // localStorage.setItem("expiaryDate", res.data.expires);
          // localStorage.setItem("userId", res.data.user.id);
          // localStorage.setItem("firstName", res.data.user.firstName);
          // localStorage.setItem("lastName", res.data.user.lastName);
          // localStorage.setItem("userName", res.data.user.userName);
          // localStorage.setItem("profileImage", res.data.user.profileImage);
          // localStorage.setItem("socialMediaUser", true);

          // console.log(res.data.expires)
          window.location.href = "/dashboard";
        }
      })
      .catch(function (res) {
        console.log("the gogole login issue ");
        console.log(res);
      });
  };

  render() {
    return (
      <form className="login-form sign-up-form">
        <img src={Logo} className="fdLogo" />
        <h3 className="signin">Sign Up</h3>
        {this.state.loading && (
          <Loader
            type="ThreeDots"
            color="#00a4df"
            height={50}
            width={50}
            style={{ textAlign: "center" }}
          />
        )}
        <div class="form-row" style={{ marginBottom: 10 }}>
          <label>
            Username<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group ">
            <div className="input-group-prepend">
              <span className="input-group-text form-icons ">
                <User color="#00a4df" size={18} />
              </span>
            </div>

            <input
              onChange={this.handleChange}
              name="username"
              type="text"
              className="form-control"
              placeholder="Username"
            />
          </div>
        </div>

        <div class="form-row" style={{ marginBottom: 10 }}>
          <div className="form-group col-md-6" style={{ paddingLeft: 0 }}>
            <label>
              First name<span style={{ color: "red" }}>*</span>
            </label>

            <div className="input-group ">
              <div className="form-group-prepend">
                <span
                  className="input-group-text form-icons "
                  style={{ height: 38 }}
                >
                  <UserCheck color="#00a4df" size={18} />
                </span>
              </div>

              <input
                onChange={this.handleChange}
                name="firstName"
                type="text"
                className="form-control"
                placeholder="First name"
              />
            </div>
          </div>

          <div className="form-group col-md-6" style={{ paddingLeft: 0 }}>
            <label>
              Last name<span style={{ color: "red" }}>*</span>
            </label>

            <div className="input-group ">
              <div className="form-group-prepend">
                <span
                  className="input-group-text form-icons "
                  style={{ height: 38 }}
                >
                  <UserCheck color="#00a4df" size={18} />
                </span>
              </div>
              <input
                type="text"
                onChange={this.handleChange}
                name="lastName"
                className="form-control"
                placeholder="Last name"
              />
            </div>
          </div>
        </div>

        <div class="form-row" style={{ marginBottom: 0 }}>
          <div className="form-group col-md-6" style={{ paddingLeft: 0 }}>
            <label>
              Email address
              <span style={{ color: "red" }}>*</span>
            </label>

            <div className="input-group ">
              <div className="form-group-prepend">
                <span
                  className="input-group-text form-icons "
                  style={{ height: 38 }}
                >
                  <Mail color="#00a4df" size={18} />
                </span>
              </div>

              <input
                type="email"
                name="email"
                onChange={this.handleChange}
                className="form-control"
                placeholder="Enter email"
              />
            </div>
          </div>

          <div className="form-group col-md-6" style={{ paddingLeft: 0 }}>
            <label>
              Phone<span style={{ color: "red" }}>*</span>
            </label>

            <div className="input-group ">
              <div className="form-group-prepend">
                <span
                  className="input-group-text form-icons "
                  style={{ height: 38 }}
                >
                  <Phone color="#00a4df" size={18} />
                </span>
              </div>
              <input
                type="tel"
                maxLength="10"
                onChange={this.handleChange}
                name="phone"
                className="form-control"
                placeholder="Enter Phone"
              />
            </div>
          </div>
        </div>

        {/* <div className="form-row">
          <div class="form-group col-md-6">
            <label>
              Email address<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter email"
            />
          </div>

          <div class="form-group col-md-6">
            <label>
              Phone<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="tel"
              maxLength="10"
              onChange={this.handleChange}
              name="phone"
              className="form-control"
              placeholder="Enter Phone"
            />


          </div>


        </div> */}

        <div class="form-row" style={{ marginBottom: 20 }}>
          <label>
            Password<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group ">
            <div className="input-group-prepend">
              <span
                className="input-group-text form-icons"
                onClick={this.toggleShow}
              >
                {this.state.hidden ? (
                  <EyeOff color="#00a4df" size={18} />
                ) : (
                  <Eye color="#00a4df" size={18} />
                )}
              </span>
            </div>

            <input
              onChange={this.handlePasswordChange}
              type={this.state.hidden ? "password" : "text"}
              name="password"
              data-tip="Click the Eye icon to show password"
              className="form-control"
              placeholder="Enter password"
            />
            <ReactTooltip backgroundColor="#00a4df" textColor="white" />
          </div>
        </div>

        <div class="form-row" style={{ marginBottom: 20 }}>
          <label>
            Confirm Password
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group ">
            <div className="input-group-prepend">
              <span
                className="input-group-text form-icons"
                onClick={this.toggleShow}
              >
                {this.state.hidden ? (
                  <EyeOff color="#00a4df" size={18} />
                ) : (
                  <Eye color="#00a4df" size={18} />
                )}
              </span>
            </div>

            <input
              type={this.state.hidden ? "password" : "text"}
              onChange={this.passwordConfirmation}
              name="passwordConfirmation"
              className="form-control"
              placeholder="Enter password"
              data-tip="Click the Eye icon to show password"
            />
            <ReactTooltip backgroundColor="#00a4df" textColor="white" />
          </div>
        </div>

        {/* <div className="form-group">
          <label>
            Password<span style={{ color: "red" }}>*</span>
          </label>
          <input
            onChange={this.handlePasswordChange}
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter password"
          />
        </div> */}

        {/* <div className="form-group">
          <label>
            Confirm Password<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="password"
            onChange={this.passwordConfirmation}
            name="passwordConfirmation"
            className="form-control"
            placeholder="Enter password"
          />
        </div> */}

        <span
          style={{
            width: "100%",
            textAlign: "center",
            display: "block",
            color: "red",
            marginBottom: 20,
          }}
        >
          {this.state.passErrorMsg}{" "}
        </span>
        <span
          style={{
            width: "100%",
            textAlign: "center",
            display: "block",
            color: "red",
            marginBottom: 20,
          }}
        >
          {this.state.confirmErrorMsg}{" "}
        </span>

        <div className="form-row">
          <button
            onClick={this.register}
            type="button"
            className="btn btn-info btn-block login-button"
          >
            Sign Up
          </button>
        </div>

        <div
          className="links-below-button sign-up-social-logins"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <GoogleLogin
            clientId="478345164350-foia8o5v2beq4c3g4cpiaptiot7gqgni.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                outline
                className="login-with-google"
                color="primary"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="15" height="15"><path fill="#FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/><path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"/><path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0124 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"/><path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/></svg>
                 Login with Google
              </Button>
            )}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={"single_host_origin"}
          />

          <AppleLogin
            clientId="com.fueldonkey.fueldonkey"
            redirectURI="https://api.staging.fueldonkey.vogdevelopment.com/signin-apple"
            designProp={{
              height: 50,
              width: 156,
              color: "white",
              border: true,
              type: "sign-in",
              border_radius: 13,
              scale: 1,
              locale: "en_US",
            }}
          />
        </div>

        <p className="forgot-password text-right nav-link already-registered">
          Already registered ? <a href="/sign-in">Log In</a>
        </p>
      </form>
    );
  }
}
