import React, { Component } from "react";
import Logo from "../images/fdLogo.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Mail } from "react-feather";

export default class ForgotPassword extends Component {
  state = {
    username: "",
    loading: false,
  };
  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value }, () => console.log(target.value));
  };

  forgot = async () => {
    const vm = this;
    vm.setState({ loading: true });
    let data = {
      username: vm.state.username,
    };

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API}/account/forgotpassword`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.code === "INVALID_CREDENTIALS") {
          vm.setState({ loading: false });

          swal({
            title: "Error!",
            text: "Invalid username or Password!",
            icon: "info",
            button: "Try again!",
          });
        } else if (res.data.code === "SUCCESS") {
          vm.setState({ loading: false });
          swal({
            title: "Email sent!!",
            text: "Please follow link in the Email!",
            icon: "success",
            button: "Ok",
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        vm.setState({ loading: false });

        if (error.response.data.code === "NOT_FOUND") {
          swal({
            title: "Error!",
            text: `${error.response.data.message}, Please sign up to use Fuel Donkey`,
            icon: "info",
            button: "Ok",
          });
        } else {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "info",
            button: "Ok",
          });
        }
      });
  };
  render() {
    return (
      <form className="login-form">
        <img src={Logo} className="fdLogo" />
        <h3 className="signin">Forgot Password</h3>
        {this.state.loading && (
          <Loader
            type="ThreeDots"
            color="#00a4df"
            height={50}
            width={50}
            style={{ textAlign: "center" }}
          />
        )}
        <div className="form-group">
          <label>Username</label>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text form-icons ">
                <Mail color="#00a4df" size={18} />
              </span>
            </div>

            <input
              type="email"
              name="username"
              className="form-control"
              placeholder="Enter email"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <button
          type="button"
          onClick={this.forgot}
          className="btn btn-info btn-block login-button"
        >
          Submit
        </button>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className="nav-link" to={"/sign-in"}>
            Back to Sign in
          </Link>
        </div>
      </form>
    );
  }
}
