import React, { Component } from "react";
import Logo from "../images/fdLogo.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Mail, Lock, Eye, EyeOff } from "react-feather";
import ReactTooltip from "react-tooltip";
import "./login.css";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import { Button } from "reactstrap";
import ReactDOM from "react-dom";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: "",
      username: "",
      errorCode: "",
      // clientId: "",
      userId: null,
      loading: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }

    const script = document.createElement("script");

    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script.async = true;

    document.body.appendChild(script);
  }

  loginUser = async () => {
    const vm = this;
    vm.setState({ loading: true });
    let data = {
      username: vm.state.username,
      password: vm.state.password,
      clientId: "webclient",
      twoFactorCode: "",
    };

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API}/account/login`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.code === "INVALID_CREDENTIALS") {
          vm.setState({ loading: false });

          swal({
            title: "Error!",
            text: "Invalid username or Password!",
            icon: "info",
            button: "Try again!",
          });
        } else if (res.data.code === "SUCCESS") {
          vm.setState({ loading: false });

          window.location.href = "/dashboard";
          sessionStorage.setItem("accessToken", res.data.data.accessToken);
          sessionStorage.setItem("refreshToken", res.data.data.refreshToken);
          sessionStorage.setItem("expiaryDate", res.data.data.expires);

          // //console.log(res.data.expires)
        }
      });
  };

  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value }, () => console.log(target.value));
  };

  responseGoogle = async (response) => {
    console.log("here", response);
    const vm = this;

    let data = {};
    data.accessToken = response.tokenId;

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API}/account/login/google`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then(function (res) {
        console.log(res);

        if (res.code === "INVALID_CREDENTIALS") {
          // console.log('wrong info');
          vm.setState({ errorCode: res.message });
        } else if (res.data.code === "SUCCESS") {
          sessionStorage.setItem("accessToken", res.data.data.accessToken);
          // localStorage.setItem("refreshToken", res.data.refreshToken);
          // localStorage.setItem("expiaryDate", res.data.expires);
          // localStorage.setItem("userId", res.data.user.id);
          // localStorage.setItem("firstName", res.data.user.firstName);
          // localStorage.setItem("lastName", res.data.user.lastName);
          // localStorage.setItem("userName", res.data.user.userName);
          // localStorage.setItem("profileImage", res.data.user.profileImage);
          // localStorage.setItem("socialMediaUser", true);

          // console.log(res.data.expires)
          window.location.href = "/dashboard";
        }
      })
      .catch(function (res) {
        console.log("the gogole login issue ");
        console.log(res);
      });
  };
  render() {
    return (
      <form onChange={this.handleChange} className="login-form">
        <img src={Logo} className="fdLogo" />
        <h3 className="signin">Log In</h3>
        {this.state.loading && (
          <Loader
            type="ThreeDots"
            color="#00a4df"
            height={50}
            width={50}
            style={{ textAlign: "center" }}
          />
        )}
        <label>Username </label>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text form-icons ">
              <Mail color="#00a4df" size={18} />
            </span>
          </div>
          <input
            type="username"
            name="username"
            className="form-control"
            aria-describedby="basic-addon1"
            placeholder="Enter Email"
            required
          />
        </div>

        <label>Password</label>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span
              className="input-group-text form-icons"
              onClick={this.toggleShow}>
              {this.state.hidden ? (
                <EyeOff color="#00a4df" size={18} />
              ) : (
                <Eye color="#00a4df" size={18} />
              )}
            </span>
          </div>

          <input
            onChange={(e) => this.setState({ password: e.target.value })}
            type={this.state.hidden ? "password" : "text"}
            value={this.state.password}
            onChange={this.handlePasswordChange}
            name="password"
            className="form-control"
            placeholder="Enter Password"
            data-tip="Click the Eye icon to show password"
            required
          />

          <ReactTooltip backgroundColor="#00a4df" textColor="white" />
        </div>

        {/* <div className="form-group">
          <div className="custom-control custom-checkbox">
          <input
          type="checkbox"
          className="custom-control-input"
          id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
          Remember me
          </label>
          </div>
        </div> */}

        <button
          onClick={this.loginUser}
          type="button"
          className="btn btn-info btn-block login-button">
          Submit
        </button>
        <div
          className="links-below-button"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <GoogleLogin
            clientId="862536629509-ghtgq75e6spbkjt3pqc3pob3knogjs2o.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                outline
                className="login-with-google"
                color="primary"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="15"
                  height="15">
                  <path
                    fill="#FFC107"
                    d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
                  />
                  <path
                    fill="#FF3D00"
                    d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0124 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
                  />
                </svg>{" "}
                Login with Google
              </Button>
            )}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={"single_host_origin"}
          />

          <AppleLogin
            clientId={"com.fueldonkey.mobile"}
            redirectURI={process.env.REACT_APP_APPLE}
            responseType={"code id_token"}
            // scope={"name email"}
            responseMode={"fragment"}
            usePopup={true}
            designProp={{
              height: 50,
              width: 156,
              color: "white",
              border: true,
              type: "sign-in",
              border_radius: 13,
              scale: 1,
              locale: "en_US",
            }}
          />
          {/* <div
            id="appleid-signin"
            data-color="black"
            data-border="true"
            data-type="sign in"
          ></div> */}
        </div>
        <div
          className="links-below-button"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Link className="nav-link" to={"/sign-up"}>
            Sign up
          </Link>
          <Link className="nav-link" to={"/forgot-password"}>
            Forgot Password
          </Link>
        </div>
      </form>
    );
  }
}
