import React, { Component } from "react";
import Logo from "../images/fdLogo.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Mail } from "react-feather";

export default class AppleLogin extends Component {
  state = {
    username: "",
    loading: false,
    idToken: "",
    isMounted: false,
  };
  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value }, () => console.log(target.value));
  };

  loginWithApple = () => {
    const vm = this;
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    let data = {
      idToken: vm.state.idToken,
    };
    axios
      .post(
        `${process.env.REACT_APP_API}/account/login/apple/web`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then(function (res) {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          vm.setState({ isMounted: true });
          sessionStorage.setItem("accessToken", res.data.data.accessToken);
          sessionStorage.setItem("refreshToken", res.data.data.refreshToken);
          sessionStorage.setItem("expiaryDate", res.data.data.expires);
        } else {
          //   swal("Something Went Wrong, try again later!");
        }
      })
      .catch((error) => {
        // Error
        console.log(error);
        swal("Error", "Something went wrong, Please try again later", "info");
      });
  };

  componentDidMount() {
    var url = window.location.hash;
    if (url) {
      var token = url
        .split("&")
        .filter(function (el) {
          if (el.match("id_token") !== null) return true;
        })[0]
        .split("=")[1];
    }

    this.setState({ idToken: token }, () => this.loginWithApple());
  }

  render() {
    return (
      <form className="login-form">
        <img src={Logo} className="fdLogo" />
        {this.state.isMounted ? (
          <div>
            <h3 className="signin">Successfully Signed-in with AppleID</h3>
            {this.state.loading && (
              <Loader
                type="ThreeDots"
                color="#00a4df"
                height={50}
                width={50}
                style={{ textAlign: "center" }}
              />
            )}
            <button
              type="button"
              onClick={() => (window.location.href = "/dashboard")}
              className="btn btn-info btn-block login-button"
            >
              Go to Dashboard
            </button>{" "}
          </div>
        ) : (
          <div>
            <h3 className="signin">Failed, Please try again!</h3>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link className="nav-link" to={"/sign-in"}>
                Go Back
              </Link>
            </div>
          </div>
        )}
      </form>
    );
  }
}
