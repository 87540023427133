import React, { Component } from "react";
import Logo from "../../images/fdLogo.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import { Button, Input } from "reactstrap";
import swal from "sweetalert2";
import Loader from "react-loader-spinner";
import "./index.css";
export default class Dashboard extends Component {
  state = {
    userInfo: {},
    productIdentifier: "",
    productIdentifierList: [],
    isSubscribed: null,
  };
  componentDidMount() {
    this.getUserInfo();
    this.getProductIdentifier();
    this.isSubscribed();
  }

  getUserInfo = async () => {
    const vm = this;
    vm.setState({ loading: true });

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    };

    await axios
      .get(`${process.env.REACT_APP_API}/users/me`, {
        headers: header,
      })
      .then((res) => {
        console.log(res);

        if (res.data.code === "SUCCESS") {
          vm.setState({
            loading: false,
            userInfo: res.data.data,
          });
        } else {
          window.location.href = "/sign-in";
        }
      });
  };

  getProductIdentifier = async () => {
    const vm = this;
    vm.setState({ loading: true });

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    };

    await axios
      .get(`${process.env.REACT_APP_API}/memberships`, {
        headers: header,
      })
      .then((res) => {
        console.log(res);

        if (res.data.code === "SUCCESS") {
          vm.setState({
            loading: false,
            productIdentifierList: res.data.data.items,
          });
        } else {
          window.location.href = "/sign-in";
        }
      });
  };

  handleToken = async (token) => {
    const vm = this;
    let data = {
      stripeToken: token.id,
      productIdentifier: vm.state.productIdentifier,
    };
    console.log(data);
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    };
    vm.setState({ loading: true });

    await axios
      .post(
        `${process.env.REACT_APP_API}/users/subscribe/stripe`,
        JSON.stringify(data),
        {
          headers: header,
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          vm.setState({ loading: false });

          swal
            .fire({
              html:
                "<b>Subscribed  </br>Thank you for purchasing a Fuel Donkey membership!</b> </br> </br>" +
                `<div class="swal-htm-text"><ul>Here is how the membership gets applied to your profile:</br>
            <ul> <li>The membership is linked to your email which is your sign-in for the app.</li>
            <li> Upon signing in, adding your vehicle and payment information, the discount for the delivery fee will be taken off automatically when refueling has been scheduled</li>
            <li> See the membership details below for what your purchase includes.</li>
            <li> If you have any problems please contact support@fueldonkey.ca</li></ul></div>`,
              icon: "success",
              width: 700,

              customClass: "sweetalert-lg",
              closeOnClickOutside: false,
            })
            .then(function () {
              window.location.reload();
            });
        } else {
          swal.fire(res.data.message)
          .then(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        vm.setState({ loading: false });

        swal.fire({
          title: "Error!",
          text: error.response.data.message,
          icon: "info",
          button: "Ok",
        });
      });
  };

  unsubscribe = () => {
    swal
      .fire({
        title: "Are you sure you want to Cancel Subscription?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          let data = {
            clearSavedCards: true,
          };
          console.log(data);
          const header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          };

          axios
            .post(
              `${process.env.REACT_APP_API}/users/subscribe/stripe/unsubscribe`,
              JSON.stringify(data),
              {
                headers: header,
              }
            )
            .then(function (res) {
              //console.log(res);
              if (res.data.code === "SUCCESS") {
                swal
                  .fire("Membership unsubscribed!", {
                    button: false,
                    closeOnClickOutside: false,
                  })
                  .then(function () {
                    window.location.reload();
                  });
              } else {
                // vm.setState({ redirect: true });
              }
            })
            .catch(function (err) {
              //console.log(err);
              // vm.setState({ redirect: true });
            });
        } else {
          swal.fire({ text: "Subscription was not cancelled!" });
        }
      });
  };

  isSubscribed = async () => {
    const vm = this;
    vm.setState({ loading: true });

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    };

    await axios
      .get(`${process.env.REACT_APP_API}/users/me/subscribed`, {
        headers: header,
      })
      .then((res) => {
        console.log(res);

        if (res.data.code === "SUCCESS") {
          vm.setState({
            loading: false,
            isSubscribed: res.data.data.subscribed,
          });
        } else {
          window.location.href = "/sign-in";
        }
      });
  };

  logOut = () => {
    console.log("LogOut");
    sessionStorage.clear();
    window.location.href = "/sign-in";
  };

  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value }, () => console.log(target.value));
  };

  render() {
    const { stripe } = this.props;
    return (
      <div className="index-page">
        <nav className="navbar navbar-expand-lg navbar-light fixed-top pt-2 pb-2">
          <div className="container">
            <img src={Logo} style={{ height: 50 }} />
            <span className="logo-text">Fuel Donkey Membership Dashboard </span>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-auto">
                <span className="username">
                  {" "}
                  {this.state.userInfo.firstName} {this.state.userInfo.lastName}{" "}
                </span>
                <li
                  onClick={this.logOut}
                  style={{ cursor: "pointer" }}
                  className="nav-item logout">
                  Log Out
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {this.state.loading && (
          <Loader
            type="ThreeDots"
            color="#00a4df"
            height={50}
            width={50}
            style={{ textAlign: "center" }}
          />
        )}
        <div className="main-container-dashboard">
          <h1 className="main-title">Memberships & Subscriptions</h1>

          <div className="current-subs">
            {this.state.isSubscribed ? (
              <p style={{ fontSize: 20 }}>
                You have an active Memberships currently.{" "}
              </p>
            ) : (
              <p style={{ fontSize: 20 }}>
                You do not have any Memberships currently.{" "}
              </p>
            )}

            {!this.state.isSubscribed && (
              <p>Select a membership to get started. </p>
            )}
            <form className="subscription-form">
              {this.state.isSubscribed ? (
                <div className="form-row align-items-center">
                  <button
                    type="button"
                    onClick={this.unsubscribe}
                    class="btn btn-danger">
                    Cancel subscription
                  </button>
                </div>
              ) : (
                <div className="form-row align-items-center">
                  <div className="col-auto my-1">
                    <select
                      className="custom-select mr-sm-2"
                      id="inlineFormCustomSelect"
                      onChange={this.handleChange}
                      value={
                        this.state.productIdentifier
                          ? this.state.productIdentifier
                          : ""
                      }
                      name="productIdentifier">
                      <option>Select a Membership</option>
                      {this.state.productIdentifierList.map((prod) => (
                        <option key={prod.id} value={prod.identifier}>
                          {prod.friendlyName} - ${prod.total}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-auto my-1">
                    {/* <button type="submit" class="btn btn-primary buy">
                    Buy this subscription
                  </button> */}
                    <StripeCheckout
                      stripeKey={process.env.REACT_APP_STRIPE}
                      token={this.handleToken}
                      className="btn btn-success"
                      name="Make Payment">
                      <Button
                        type="button"
                        disabled={
                          this.state.totalRentalAmount <= 0 ? true : false
                        }
                        block
                        className="btn btn-primary buy">
                        Buy this subscription
                      </Button>
                    </StripeCheckout>
                  </div>
                </div>
              )}
            </form>
          </div>

          <div className="old-subs">
            <p>Memberships: </p>

            <div className="pricing-table group">
              {this.state.productIdentifierList.map((prod, index) => (
                <div key={prod.id} className="blockk personal fl">
                  <h3 className="title">{prod.friendlyName}</h3>

                  <div className="content">
                    <p className="price">
                      <sup>$</sup>
                      <span style={{ letterSpacing: 0 }}>{prod.total}</span>
                      <sub>/year.</sub>
                    </p>
                    <p className="hint">- {index > 0 ? "50" : "3"} vehicles</p>
                    <p className="hint">
                      - {index > 0 ? "Unlimited" : "10"} fills total per month
                    </p>
                    {index === 0 && (
                      <>
                        <p className="hint">
                          - Additional fills are $3 /delivery
                        </p>
                        <p className="hint">
                          - Fill totals cannot be rolled over to the next month
                        </p>
                      </>
                    )}
                    <p className="hint">- 30 day cancellation notice</p>
                    <p className="hint">
                      - Vehicles can be changed 3 times per year
                    </p>
                    <p className="hint">
                      - Payment information can be changed 3 times per year
                    </p>
                    <p className="hint">
                      - Any vehicle or payment changes beyond 3 will need to
                      contact support@fueldonkey.ca
                    </p>
                  </div>

                  {/* <div className="pt-footer">
                    <span>Cancel Membership</span>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        <nav className="navbar footer navbar-expand-lg navbar-light fixed-bottom pt-2 pb-2">
          <div className="container">
            <span className="">© Fuel Donkey 2020 </span>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-auto">
                <a
                  href="https://fueldonkey.com/privacy-policy/"
                  target="_blank">
                  <li
                    style={{ cursor: "pointer", color: "#212529" }}
                    className="nav-item ">
                    Privacy Policy
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </nav>
        {/* Welcome {this.state.userInfo.firstName} {this.state.userInfo.lastName} */}
        {/* <Input
          type="select"
          name="productIdentifier"
          id="productIdentifier"
          value={
            this.state.productIdentifier ? this.state.productIdentifier : ""
          }
          onChange={this.handleChange}
        >
          {this.state.productIdentifierList.map((prod) => (
            <option key={prod.id} value={prod.identifier}>
              {prod.friendlyName}
            </option>
          ))}
        </Input> */}
      </div>
    );
  }
}
