import React, { Component } from "react";
import Logo from "../images/fdLogo.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "react-loader-spinner";

export default class Verify extends Component {
  state = {
    smsVerificationToken: "",
    smsVerificationCode: "",
    tempUser: JSON.parse(sessionStorage.getItem("tempUser")),
  };

  handleChange = (e) => {
    const vm = this;
    let target = e.target;
    let name = target.name;
    vm.setState({ [name]: target.value });
  };

  register = async () => {
    const vm = this;
    console.log(vm.state);
    if (vm.state.tempUser !== null && vm.state.smsVerificationCode !== "") {
      let data = {
        username: vm.state.tempUser.username,
        firstName: vm.state.tempUser.firstName,
        lastName: vm.state.tempUser.lastName,
        password: vm.state.tempUser.password,
        email: vm.state.tempUser.email,
        passwordConfirmation: vm.state.tempUser.passwordConfirmation,
        phone: vm.state.tempUser.phone,
        smsVerificationToken: sessionStorage.getItem("smsVerificationToken"),
        smsVerificationCode: vm.state.smsVerificationCode,
      };
      console.log("True");

      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/account/register`,
          JSON.stringify(data),
          {
            headers: header,
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.code === "SUCCESS") {
            sessionStorage.removeItem("tempUser", res.config.data);
            sessionStorage.removeItem(
              "smsVerificationToken",
              res.data.data.smsVerificationToken
            );
            swal({
              title: "Registration Successful!",
              text: `Please login!`,
              icon: "success",
              button: "Ok",
            });
            setInterval(function () {
              window.location.href = "/login";
            }, 1500);

            vm.setState({ loading: false });
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          if (error.response.data.code === "VALIDATION_ERROR") {
            swal({
              title: "Error!",
              text: `${error.response.data.message}, Invalid PIN or Phone number Mismatch`,
              icon: "info",
              button: "Ok",
            });
          } else {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "info",
              button: "Ok",
            });
          }
        });
    } else if (vm.state.smsVerificationCode === "" || null) {
      swal({
        title: "Error!",
        text: "Enter Code!",
        icon: "info",
        button: "Ok",
      });
    } else {
      swal({
        title: "Error!",
        text: "Please Register again, Verification Period Expired!",
        icon: "error",
        button: "Ok",
      });
    }
  };

  render() {
    console.log(JSON.parse(sessionStorage.getItem("tempUser")));

    return (
      <form onChange={this.handleChange} className="login-form">
        <img src={Logo} className="fdLogo" />
        <h3>Phone Verification</h3>

        <div className="form-group">
          <label>Enter Code</label>
          <input
            type="text"
            name="smsVerificationCode"
            className="form-control"
            placeholder="Enter code"
          />
        </div>

        <button
          onClick={this.register}
          type="button"
          className="btn btn-info btn-block login-button"
        >
          Submit
        </button>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className="nav-link" to={"/sign-in"}>
            Back to Sign in
          </Link>
        </div>
      </form>
    );
  }
}
